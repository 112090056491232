import type { EventHandler, MouseEvent, KeyboardEvent } from 'react'

export type IndifferentHandler = EventHandler<MouseEvent | KeyboardEvent>

export interface ElementProps {
  tabIndex: number
  onClick: EventHandler<MouseEvent>
  onKeyDown: EventHandler<KeyboardEvent>
}

/**
 * Given an indifferent event handler that can respond to either a mouse or keyboard event,
 * returns props that will make the element focusable and respond to the spacebar and `Return`
 * keys as though it was clicked.
 *
 * Note that this is only necessary when using an element that is not normally interactive.
 * Buttons, checkboxes and radio inputs already have this behaviour. When using this on any
 * other DOM element, you will need to apply an appropriate aria-role attribute (e.g. "checkbox").
 */
export function keyboardClickable({
  onClick,
  tabIndex = 0,
}: {
  onClick?: IndifferentHandler
  tabIndex?: number
}): ElementProps | undefined {
  if (!onClick) {
    return
  }

  return {
    tabIndex,
    onClick,
    onKeyDown: (evt: KeyboardEvent) => {
      switch (evt.key) {
        case ' ': // spacebar
          evt.preventDefault() // prevent scrolling
        case 'Enter':
          onClick(evt)
          evt.stopPropagation()
      }
    },
  }
}
