import { CSSObject } from '@emotion/react'
import { CSSProperties } from 'react'

import { assignLiteral, assignToken, Responsive, ResponsiveCSS } from './assignStyles'
import { space } from '../tokens'

export interface FlexContainerProps {
  /** Which direction to flow content in this container. */
  flexDirection?: Responsive<CSSProperties['flexDirection']>
  /** How to wrap content when it exceeds the space available. */
  flexWrap?: ResponsiveCSS<'flexWrap'>
  /** Aligns content within the container along the main axis. If the flex direction is `"row"` (the default) this aligns content horizontally. */
  justifyContent?: ResponsiveCSS<'justifyContent'>
  /** Sets `justify-self` on each item in the container. Not well supported. */
  justifyItems?: ResponsiveCSS<'justifyItems'>
  /** Aligns content along the secondary axis. If the flex direction is `"row"` (the default) this aligns content vertically. */
  alignContent?: ResponsiveCSS<'alignContent'>
  /** Sets `align-self` on each item in the container. Not well supported. */
  alignItems?: ResponsiveCSS<'alignItems'>
  /** Sets the `gap` property. */
  gap?: Responsive<number>
}

export function flexContainer(props: FlexContainerProps) {
  const styles: CSSObject = { display: 'flex' }
  assignToken(styles, space, 'gap', props.gap)
  assignLiteral(styles, 'flexDirection', props.flexDirection)
  assignLiteral(styles, 'flexWrap', props.flexWrap)
  assignLiteral(styles, 'justifyItems', props.justifyItems)
  assignLiteral(styles, 'justifyContent', props.justifyContent)
  assignLiteral(styles, 'alignItems', props.alignItems)
  assignLiteral(styles, 'alignContent', props.alignContent)
  return styles
}

export interface FlexItemProps {
  /** Sets the initial main size of a flex item. */
  flexBasis?: ResponsiveCSS<'flexBasis'>
  /** Sets how much of the available space in the flex container should be assigned to that item (the flex grow factor). */
  flexGrow?: ResponsiveCSS<'flexGrow'>
  /** Sets the shrink factor of a flex item. If the size of flex items is larger than the flex container, items shrink to fit according to `flexShrink`. */
  flexShrink?: ResponsiveCSS<'flexShrink'>
  /** Aligns this item along the secondary axis, overriding the containers `alignContent` value. */
  alignSelf?: ResponsiveCSS<'alignSelf'>
}

export function flexItem(props: FlexItemProps) {
  const styles: CSSObject = {}
  assignLiteral(styles, 'flexBasis', props.flexBasis)
  assignLiteral(styles, 'flexGrow', props.flexGrow)
  assignLiteral(styles, 'flexShrink', props.flexShrink)
  assignLiteral(styles, 'alignSelf', props.alignSelf)
  return styles
}
