import isPropValid from '@emotion/is-prop-valid'
import { createContext, useContext } from 'react'

import styled from '../styled'
import { ColorProps, colors } from '../styleFunctions/colors'
import { FlexContainerProps, flexItem, FlexItemProps } from '../styleFunctions/flex'
import { font, FontProps, isFontProp } from '../styleFunctions/font'
import { sizing, SizingProps } from '../styleFunctions/sizing'
import { spacing, SpacingProps } from '../styleFunctions/spacing'
import { variants } from '../styleFunctions/variants'
import { ButtonColor } from '../themeTypes'
import { fontFamily, fontSize, radius, space } from '../tokens'

export interface Props
  extends FontProps,
    SizingProps,
    SpacingProps,
    FlexContainerProps,
    FlexItemProps,
    ColorProps {
  /** Set a themed color preset by name (primary, secondary, etc.) */
  color?: ButtonColor
  /** The size of the button (big, compact, etc.) */
  size?: ButtonSize
  disabled?: boolean
  /** If true, the `display` CSS property will be set to `block-inline` */
  inline?: boolean
  borderless?: boolean
  filled?: boolean
}

export const SizeStyles = {
  normal: {
    fontSize: fontSize(3),
    padding: space(2),
    minHeight: space(5),
  },
  small: {
    fontSize: fontSize(2),
    padding: space(2),
    gap: space(1),
  },
  compact: {
    fontSize: fontSize(2),
    paddingLeft: space(2),
    paddingRight: space(2),
    minHeight: space(5),
  },
  big: {
    fontSize: fontSize(3),
    padding: space(3),
    justifyContent: 'center',
  },
}

/**
 * `Button` defines visual props for buttons using our styling system. For form
 * submissions you should use a [`SpinnerButton`](#spinnerbutton) to show the
 * user a request is in progress.
 *
 * @component
 */
export const Button = styled('button', {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'color' && !isFontProp(prop),
})<Props>(
  {
    gap: space(2),
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    fontFamily: fontFamily('default'),
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    lineHeight: 1,
    justifyContent: 'center',
    textDecoration: 'none',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: radius(2),
    transition: 'filter 0.25s ease-out, opacity 0.25s ease-out',
    filter: 'saturate(100%)',

    ':disabled': {
      filter: 'saturate(70%)',
      opacity: 0.66,
    },

    ':active': {
      filter: 'saturate(80%)',
      opacity: 0.66,
    },
  },
  ({ inline }) => ({ display: inline ? 'block-inline' : 'flex' }),
  ({ theme }) => theme.styles.Button,
  ({ theme, disabled = false }) =>
    disabled ? { pointerEvents: 'none' } : theme.styles.ButtonHover,
  variants('size', SizeStyles, 'normal'),
  ({ color = 'default', filled: overrideFilled, theme }) => {
    const defaultFilled = useContext(FilledPropContext)[color]
    const filled = typeof overrideFilled === 'boolean' ? overrideFilled : defaultFilled
    const variants = theme.buttonColors[color]
    return variants[filled ? 'filled' : 'outline']
  },
  ({ borderless }) =>
    borderless
      ? {
          borderStyle: 'none',
          backgroundColor: 'transparent',
          '&:hover': { backgroundColor: 'transparent' },
        }
      : undefined,
  colors,
  font,
  sizing,
  spacing,
  flexItem,
)

export type ButtonSize = keyof typeof SizeStyles
export const ButtonSizes: ButtonSize[] = Object.keys(SizeStyles) as ButtonSize[]

type FlagDefaults = { [C in ButtonColor]?: boolean }

// context that allows defaulting certain button colors to be filled=true
const FilledPropContext = createContext<FlagDefaults>({})
const solidButtonsOverrides = { primary: true, secondary: true, danger: true }

// default filled=true for primary/secondary/danger buttons
export function SolidButtons({ children }: { children: React.ReactNode }) {
  return (
    <FilledPropContext.Provider value={solidButtonsOverrides}>
      {children}
    </FilledPropContext.Provider>
  )
}
